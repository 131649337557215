<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 240px;" @search="loadOrders(true)" />
                </div>
            </div>
            <el-table :data="orders.data" class="mt-2">
                <el-table-column prop="billNumber" label="单据编号" sortable width="200">
                    <template #default="scope">
                        <router-link :to="'/order/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.billNumber}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="supplierName" label="供应商" sortable />
                <el-table-column prop="buyerName" label="买家" sortable />
                <el-table-column prop="paymentAmount" label="支付金额" sortable width="120" />
                <el-table-column prop="paymentMethodName" label="支付方式" sortable width="120" />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditOrderDialog(scope.row)">
                            审核
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="orders.totalRecords"
                :current-page="ordersPagination.currentPage" :page-size="ordersPagination.pageSize" class="mt-3"
                @current-change="ordersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditOrderDialogVisible" title="审核" :close-on-click-modal="false" width="960px"
            top="0px">
            <iframe v-if="auditOrderDialogVisible" :src="'/#/order/detail?id='+order.id" frameborder="0"
                class="d-block border border-primary rounded w-100" style="height: calc(100vh - 280px);"></iframe>

            <el-form class="mt-2">
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="success" @click="auditOrder(true)">审核通过</el-button>
                <el-button type="danger" @click="auditOrder(false)">审核退回</el-button>
                <el-button @click="auditOrderDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                orders: {},
                ordersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                order: {},
                auditRemark: null,
                auditOrderDialogVisible: false,
            };
        },
        methods: {
            async loadOrders(resetPage = false) {
                if (resetPage) {
                    this.ordersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Order/GetOrders', {
                    params: {
                        status: 1,
                        key: this.key,
                        pageIndex: this.ordersPagination.currentPage - 1,
                        pageSize: this.ordersPagination.pageSize,
                    }
                });
                this.orders = response.data;
            },
            ordersPaginationCurrentChange(page) {
                this.ordersPagination.currentPage = page;
                this.loadOrders();
            },
            showAuditOrderDialog(order) {
                this.order = JSON.parse(JSON.stringify(order));
                this.auditRemark = null;
                this.auditOrderDialogVisible = true;
            },
            async auditOrder(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/Order/AuditOrder', {
                    id: this.order.id,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditOrderDialogVisible = false;
                this.loadOrders();
            },
        },
        created() {
            this.loadOrders();
        },
    };
</script>